import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';
import { NgComponentOutlet } from '@angular/common';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { PrimaryButtonComponent } from '../../../shared/components/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../../../shared/components/secondary-button/secondary-button.component';
import { LearningPlanBuilderSettingsComponent } from '../../../domains/academy/learning-plan/components/learning-plan-builder-settings/learning-plan-builder-settings.component';
import { LearningPlanBuilderCourseSelectionComponent } from '../../../domains/academy/learning-plan/components/learning-plan-builder-course-selection/learning-plan-builder-course-selection.component';
import { LearningPlanBuilderOverviewComponent } from '../../../domains/academy/learning-plan/components/learning-plan-builder-overview/learning-plan-builder-overview.component';
import { LearningPlanBuilderDataService } from '../../../domains/academy/learning-plan/services/learning-plan-builder-data.service';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

interface Step {
  key: string;
  component: Type<unknown>;
  completed: boolean;
  control?: FormGroup;
}

@Component({
  selector: 'app-learning-plan-detail',
  standalone: true,
  imports: [
    MatTabsModule,
    TranslocoDirective,
    MatStep,
    MatStepper,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    NgComponentOutlet,
  ],
  animations: [easeInAnimationFast],
  templateUrl: './learning-plan-builder.component.html',
  styleUrls: ['./learning-plan-builder.component.css'],
})
export class LearningPlanBuilderComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  readonly steps: Step[] = [
    {
      key: 'settings',
      component: LearningPlanBuilderSettingsComponent,
      completed: false,
      control: this.dataService.formGroupSettings,
    },
    {
      key: 'courseSelection',
      component: LearningPlanBuilderCourseSelectionComponent,
      completed: false,
      control: this.dataService.formGroupCourseSelection,
    },
    {
      key: 'overview',
      component: LearningPlanBuilderOverviewComponent,
      completed: false,
    },
  ];

  selectedIndex = 0;

  constructor(
    private readonly translocoService: TranslocoService,
    protected readonly dataService: LearningPlanBuilderDataService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id && this.dataService.editedLearningPlanId !== id) {
        void this.router.navigate(['/portal/learningPlans', id]);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isPreviousButtonHidden(): boolean {
    return this.selectedIndex === 0;
  }

  get showNextButton(): boolean {
    return this.selectedIndex !== this.steps.length - 1;
  }

  get isNextButtonDisabled(): boolean {
    const currentControl = this.steps[this.selectedIndex].control;
    if (currentControl) {
      return !currentControl.valid;
    }
    return false;
  }

  onPreviousButtonClicked(): void {
    this.selectedIndex--;
  }

  onNextButtonClicked(): void {
    this.selectedIndex++;
  }

  getStepLabel(key: string): string {
    return this.translocoService.translate(`portal.learningPlans.builder.stepper.${key}.headline`);
  }

  onSelectedIndexChange(selectedIndex: number): void {
    this.selectedIndex = selectedIndex;
  }

  onCompleteLearningPlanClicked(): void {
    if (this.dataService.editedLearningPlanId) {
      this.dataService.editLearningPlanInstance();
    } else {
      this.dataService.createLearningPlanInstance();
    }
  }
}
