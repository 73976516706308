import {
  Component,
  ElementRef,
  AfterViewInit,
  ViewChild,
  HostListener,
  ViewEncapsulation,
  OnDestroy,
  Input,
} from '@angular/core';
import { ArrowDrawingService } from '../../services/arrow-drawing.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-arrow-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './arrow-wrapper.component.html',
  styleUrl: './arrow-wrapper.component.css',
  encapsulation: ViewEncapsulation.None,
  providers: [ArrowDrawingService],
})
export class ArrowWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild('svgContainer', { static: true }) svgContainer!: ElementRef<SVGElement>;
  @ViewChild('container', { static: true }) container!: ElementRef<Element>;

  @Input() arrowChanges$?: Observable<void>;
  private subscription?: Subscription;

  constructor(private readonly arrowService: ArrowDrawingService) {}

  ngAfterViewInit() {
    this.arrowService.initialize(this.svgContainer, this.container.nativeElement);
    this.drawArrows();
    if (this.arrowChanges$) {
      this.subscription = this.arrowChanges$.subscribe(() => {
        this.drawArrows();
      });
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.drawArrows();
  }

  private drawArrows() {
    setTimeout(() => {
      this.arrowService.drawArrows();
    }, 0);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.arrowService.cleanup();
  }
}
