import { APP_INITIALIZER, ApplicationConfig, InjectionToken, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { routes } from './app.routes';
import { LocalStorageService } from './core/services/local-storage.services';
import { TranslocoHttpLoader } from './core/i18n/transloco-loader';
import { TranslationLoader } from './core/i18n/translation-loader';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import { msalInstanceFactory, msalGuardConfigFactory, msalInterceptorConfigFactory } from './core/msal/msal-factories';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { CurrentUserState } from './core/store/current-user/current-user.state';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngxs/store';

export const APP_CONFIG = new InjectionToken('app.config');

export const appConfig: ApplicationConfig = {
  providers: [
    provideCharts(withDefaultRegisterables()),
    provideStore([CurrentUserState], {
      developmentMode: !environment.production,
      selectorOptions: { suppressErrors: false },
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    provideRouter(routes),
    provideAnimations(),
    provideAnimationsAsync('animations'),
    {
      provide: APP_INITIALIZER,
      useFactory: (localStorageService: LocalStorageService, translocoService: TranslocoService) => {
        const translationLoader = new TranslationLoader(localStorageService, translocoService);
        return async () => translationLoader.preloadTranslations();
      },
      multi: true,
      deps: [LocalStorageService, TranslocoService],
    },
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: environment.general.defaultLang,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideAnimationsAsync(),
  ],
};
