<div class="mt-10">
  <app-arrow-wrapper *transloco="let t" [arrowChanges$]="getObservablePhases()">
    @for (
      phaseGroup of dataService.getPhasesArray().controls;
      track phaseGroup;
      let i = $index
    ) {
      <div class="element_with_arrowhead mb-16 w-auto" [attr.data-index]="i">
        <app-phase-card
          [name]="getPhaseName(i)"
          [displayRemovePhaseButton]="i > 0"
          (removePhaseButtonClickedEvent)="onRemovePhaseButtonClickedEvent(i)"
        >
          <app-course-select
            [control]="phaseGroup.controls.course"
            [label]="
              t(
                'portal.learningPlans.builder.stepper.courseSelection.selectCourseButton'
              )
            "
            (selectCourse)="onCourseSelectorButtonClickedEvent(i)"
            (removeCourse)="onRemoveCourseButtonClickedEvent(i)"
          />
        </app-phase-card>
      </div>
    }

    <div class="element_with_arrowhead mb-16">
      <app-card-button
        classes="w-full px-20 py-6"
        [label]="
          t(
            'portal.learningPlans.builder.stepper.courseSelection.addPhaseButton',
            {
              phase: t(
                'portal.learningPlans.builder.stepper.courseSelection.addPhaseButtonPhases.' +
                  getPhaseKey()
              ),
            }
          )
        "
        (buttonClickedEvent)="onAddPhaseButtonClickedEvent()"
      ></app-card-button>
    </div>

    <div class="element_with_line mb-16 flex justify-center">
      <app-loop-toggle
        [checked]="dataService.formGroupCourseSelection.controls.loop.value"
        (checkedChange)="
          dataService.formGroupCourseSelection.controls.loop.setValue($event)
        "
      />
    </div>
  </app-arrow-wrapper>
</div>
