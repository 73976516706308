<div
  class="box flex max-w-[350px] flex-col rounded-lg border border-gray-300 p-4 shadow-md"
  *transloco="let t"
>
  <!--  header-->
  <div class="mb-3 flex justify-between">
    <div class="flex items-center">
      <mat-icon class="text-md mr-2 text-orange">description</mat-icon>
      <h3 class="text-md font-semibold text-primary">
        {{ t('portal.courses.courseTitleKeys.' + course.name + '.name') }}
      </h3>
    </div>
  </div>
  <!--  body-->
  <p class="text-md h- mb-3 line-clamp-3 h-[72px] text-grey">
    {{ t('portal.courses.courseTitleKeys.' + course.name + '.description') }}
  </p>
  <!--  Footer-->
  <div class="flex items-center justify-between">
    <div class="flex w-full space-x-4">
      <app-secondary-button
        class="flex-1"
        [text]="t('portal.learningPlans.builder.courseGalleryButtons.details')"
        [trailingIcon]="'open_in_new'"
        (buttonClicked)="goToExternalCourseInfoLink(course.aboutUrl)"
      >
      </app-secondary-button>
      <app-primary-button
        class="flex-1"
        [text]="t('portal.learningPlans.builder.courseGalleryButtons.select')"
        [type]="buttonType.LEADING_ADD_ICON"
        (buttonClicked)="addCourseToLearningPlan()"
      />
    </div>
  </div>
</div>
