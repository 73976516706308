import { Component, Input } from '@angular/core';
import { DatePipe, PercentPipe } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-learning-plan-statistics',
  standalone: true,
  imports: [DatePipe, PercentPipe, TranslocoDirective],
  templateUrl: './learning-plan-statistics.component.html',
})
export class LearningPlanStatisticsComponent {
  @Input({ required: true }) startAt = '';
  @Input({ required: true }) numberOfEnrolledStudents = 0;
  @Input({ required: true }) numberOfEnrolledStudentsLearningPlanStarted = 0;
  @Input({ required: true }) numberOfCompletedStudents = 0;
}
