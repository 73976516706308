<div class="mb-2 flex items-center gap-1">
  @if (label) {
    <mat-label class="text-sm font-medium">{{ label }}</mat-label>
  }
  @if (helpText) {
    <mat-icon
      class="material-icons-outlined hvs-icon-base cursor-help"
      [matTooltip]="helpText"
      matTooltipPosition="above"
      matTooltipClass="tooltip-above"
      >help_outline</mat-icon
    >
  }
</div>
<mat-form-field
  *transloco="let t"
  class="datepicker__form-field w-full"
  appearance="outline"
>
  <mat-icon
    matDatepickerToggleIcon
    matIconPrefix
    class="material-icons-outlined text-md cursor-pointer"
    (click)="picker.open()"
    >calendar_today</mat-icon
  >
  <input
    matInput
    readonly
    class="datepicker__input"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    [matDatepickerFilter]="dateFilter"
    [placeholder]="placeholder"
    [formControl]="control"
    (click)="picker.open()"
    (keydown.enter)="picker.open()"
  />
  <mat-datepicker
    #picker
    startView="multi-year"
    panelClass="datepicker__panel"
    (monthSelected)="setMonthAndYear($event, picker)"
  >
  </mat-datepicker>
  @if (control.invalid && errorMessage()) {
    <mat-error class="text-sm">
      {{ t('general.inputValidation.' + errorMessage()) }}
    </mat-error>
  }
</mat-form-field>
