import { Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CurrentUserState } from '../../../core/store/current-user/current-user.state';
import { AsyncPipe } from '@angular/common';
import { Subscription } from '../../../core/store/current-user/models/CurrentUserUIModel';
import { CourseInstancesOverviewComponent } from '../../../domains/academy/course/components/course-instances-overview/course-instances-overview.component';
import { LearningPlanCourseInstanceOverviewComponent } from '../../../domains/academy/course/components/learning-plan-course-instance-overview/learning-plan-course-instance-overview.component';
import { PrimaryButtonComponent } from '../../../shared/components/primary-button/primary-button.component';
import { ButtonType } from '../../../shared/components/primary-button/ButtonType';
import { Router } from '@angular/router';
import { LearningPlanBuilderDataService } from '../../../domains/academy/learning-plan/services/learning-plan-builder-data.service';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    TranslocoModule,
    AsyncPipe,
    CourseInstancesOverviewComponent,
    LearningPlanCourseInstanceOverviewComponent,
    PrimaryButtonComponent,
  ],
  animations: [easeInAnimationFast],
  templateUrl: './overview-container.component.html',
  styleUrl: './overview-container.component.css',
})
export class OverviewContainerComponent {
  subscription$: Observable<Subscription | null> = this.store.select(CurrentUserState.subscription);

  protected readonly buttonType = ButtonType;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly learningPlanBuilderDataService: LearningPlanBuilderDataService,
  ) {}

  onButtonClicked() {
    this.learningPlanBuilderDataService.setEditedLearningPlan(null);
    void this.router.navigate(['/portal/learningPlans/builder']);
  }
}
