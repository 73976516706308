import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-course-card',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './course-card.component.html',
})
export class CourseCardComponent {
  @Input() title: string;
  @Output() removeCourseButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick() {
    this.removeCourseButtonClickedEvent.emit();
  }
}
