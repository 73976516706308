import { Injectable } from '@angular/core';
import { PortalApiRepository } from '../../../../core/repositories/portal-api-repository';
import { CourseGalleryItemDTO } from '../models/LearningPlanDTO';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CourseGalleryService extends PortalApiRepository {
  constructor() {
    super();
  }

  getCourses(topicAreaId: string | null): Observable<CourseGalleryItemDTO[]> {
    if (!topicAreaId) {
      return EMPTY;
    }
    const path = `/topicAreas/${topicAreaId}/courses`;
    return this.get<CourseGalleryItemDTO[]>(path);
  }
}
