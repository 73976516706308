<div
  *transloco="let t"
  class="rounded-lg px-12 py-2"
  [ngClass]="{ 'bg-grey-lightGrey': !checked, 'bg-blue-light': checked }"
>
  <app-slide-toggle
    [label]="
      t('portal.learningPlans.builder.stepper.courseSelection.loopToggle')
    "
    [checked]="checked"
    (change)="onToggleChange()"
  />
</div>
