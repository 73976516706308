<div [ngClass]="'inline-flex items-center pr-2 rounded-full ' + classes">
  @if (icon) {
    <mat-icon
      [ngClass]="'items-center justify-center text-sm ' + matIconTypeClass"
      style="display: inline-flex"
      >{{ icon }}</mat-icon
    >
  } @else {
    <span [ngClass]="'w-2 h-2 m-2 rounded-full ' + dotClasses"></span>
  }
  <span class="text-xs">{{ text }}</span>
</div>
