// arrow-drawing.service.ts
import { Injectable, ElementRef } from '@angular/core';
import { select, Selection } from 'd3';

@Injectable({ providedIn: 'root' })
export class ArrowDrawingService {
  private svg?: Selection<SVGElement, unknown, null, undefined>;
  private container: Element;
  private readonly arrowHeadId: string;

  constructor() {
    this.arrowHeadId = `arrow-head-${Math.random().toString(36).substring(2, 9)}`;
  }

  initialize(svgContainer: ElementRef<SVGElement>, container: Element) {
    this.svg = select(svgContainer.nativeElement);
    this.container = container;
    setTimeout(() => {
      this.createArrowHead();
    }, 0);
  }

  drawArrows() {
    if (!this.svg) {
      return;
    }

    this.cleanup();

    const elements = Array.from(this.container.querySelectorAll('.element_with_arrowhead, .element_with_line'));

    let loopDepth = 30;
    elements.forEach((element, i) => {
      if (i < elements.length - 1) {
        const lineLength = this.drawArrow(element, elements[i + 1]);
        if (lineLength) {
          loopDepth = lineLength;
        }
      }
    });
    const radius = loopDepth / 2;
    const padding = loopDepth / 6;
    if (this.container.querySelector('.loop_enabled')) {
      this.drawLoopArrow(elements[elements.length - 1], elements[0], loopDepth, padding, radius);
    }
  }

  private drawLoopArrow(
    lastElement: Element,
    firstElement: Element,
    loopDepth: number,
    padding: number,
    radius: number,
  ) {
    if (!this.svg) {
      return;
    }

    const lastRect = lastElement.getBoundingClientRect();
    const firstRect = firstElement.getBoundingClientRect();
    const containerRect = this.svg.node()?.getBoundingClientRect();

    if (!containerRect) {
      return;
    }

    let x1 = lastRect.left + lastRect.width / 2 - containerRect.left;
    const y1 = lastRect.bottom - containerRect.top;

    let x2 = firstRect.left + firstRect.width / 2 - containerRect.left;
    const y2 = firstRect.bottom - containerRect.top;

    if (x1 === x2) {
      const selfLoopOffset = loopDepth * 3;
      x1 += selfLoopOffset;
      x2 -= selfLoopOffset;
    }

    // Vertical line 1 (downward from last element)
    this.svg
      .append('line')
      .attr('x1', x1)
      .attr('y1', y1 + padding)
      .attr('x2', x1)
      .attr('y2', y1 + loopDepth - radius)
      .attr('stroke', '#b0b6be')
      .attr('stroke-width', 1.5);

    // Right curve
    this.svg
      .append('path')
      .attr(
        'd',
        `M ${x1} ${y1 + loopDepth - radius}
       A ${radius} ${radius} 0 0 1 ${x1 - radius} ${y1 + loopDepth}`,
      )
      .attr('stroke', '#b0b6be')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none');

    // Horizontal line
    this.svg
      .append('line')
      .attr('x1', x1 - radius)
      .attr('y1', y1 + loopDepth)
      .attr('x2', x2 + radius)
      .attr('y2', y1 + loopDepth)
      .attr('stroke', '#b0b6be')
      .attr('stroke-width', 1.5);

    // Left curve
    this.svg
      .append('path')
      .attr(
        'd',
        `M ${x2 + radius} ${y1 + loopDepth}
       A ${radius} ${radius} 0 0 1 ${x2} ${y1 + loopDepth - radius}`,
      )
      .attr('stroke', '#b0b6be')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none');

    // Vertical line (upward to first element)
    this.svg
      .append('line')
      .attr('x1', x2)
      .attr('y1', y1 + loopDepth - radius)
      .attr('x2', x2)
      .attr('y2', y2 + padding)
      .attr('stroke', '#b0b6be')
      .attr('stroke-width', 1.5)
      .attr('marker-end', `url(#${this.arrowHeadId})`);
  }

  private drawArrow(startElement: Element, endElement: Element) {
    if (!this.svg) {
      return 0;
    }

    const startRect = startElement.getBoundingClientRect();
    const endRect = endElement.getBoundingClientRect();
    const containerRect = this.svg.node()?.getBoundingClientRect();
    if (!containerRect) {
      return 0;
    }

    const isHorizontal = Math.abs(endRect.left - startRect.left) > Math.abs(endRect.top - startRect.top);

    let x1, y1, x2, y2;

    if (isHorizontal) {
      x1 = startRect.right - containerRect.left;
      y1 = startRect.top + startRect.height / 2 - containerRect.top;
      x2 = endRect.left - containerRect.left;
      y2 = endRect.top + endRect.height / 2 - containerRect.top;
    } else {
      x1 = startRect.left + startRect.width / 2 - containerRect.left;
      y1 = startRect.bottom - containerRect.top;
      x2 = endRect.left + endRect.width / 2 - containerRect.left;
      y2 = endRect.top - containerRect.top;
    }

    const length = Math.hypot(x2 - x1, y2 - y1);
    const padding = length / 6;

    const angle = Math.atan2(y2 - y1, x2 - x1);
    const fixedX1 = x1 + padding * Math.cos(angle);
    const fixedY1 = y1 + padding * Math.sin(angle);
    const fixedX2 = x2 - padding * Math.cos(angle);
    const fixedY2 = y2 - padding * Math.sin(angle);

    const isArrow = endElement.classList.contains('element_with_arrowhead');

    this.svg
      .append('line')
      .attr('x1', fixedX1)
      .attr('y1', fixedY1)
      .attr('x2', fixedX2)
      .attr('y2', fixedY2)
      .attr('stroke', '#b0b6be')
      .attr('stroke-width', 1.5)
      .attr('marker-end', isArrow ? `url(#${this.arrowHeadId})` : '');

    return length;
  }

  private createArrowHead() {
    if (!this.svg) {
      return;
    }

    const defs = this.svg.append('defs');

    defs
      .append('marker')
      .attr('id', this.arrowHeadId)
      .attr('viewBox', '0 0 8 8')
      .attr('refX', '7')
      .attr('refY', '4')
      .attr('markerWidth', '8')
      .attr('markerHeight', '8')
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M 0 0 L 8 4 L 0 8 Z')
      .attr('fill', '#b0b6be');
  }

  cleanup() {
    this.svg?.selectAll('line, path:not(defs path)').remove();
  }
}
