import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forbiddenCharactersValidator } from '../../../../shared/validators/custom-validators';
import { CourseGalleryItemDTO, TypeLearningPlanInstancePOST, TypePhase } from '../models/LearningPlanDTO';
import { LearningPlanService } from './learning-plan.service';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { GetCurrentUserData } from '../../../../core/store/current-user/current-user.state';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { LearningPlanInstanceDetailUiModel } from '../models/LearningPlanInstanceDetailUIModel';

export interface TypeLearningPlanSettings {
  learningPlanName: string | null;
  topicAreaId: string | null;
  phaseFrequencyId: string | null;
  startDate: Date | null;
  notificationFrequency: string | null;
}

export interface FormGroupSettings {
  learningPlanName: FormControl<TypeLearningPlanSettings['learningPlanName']>;
  topicAreaId: FormControl<TypeLearningPlanSettings['topicAreaId']>;
  phaseFrequencyId: FormControl<TypeLearningPlanSettings['phaseFrequencyId']>;
  startDate: FormControl<TypeLearningPlanSettings['startDate']>;
  notificationFrequency: FormControl<TypeLearningPlanSettings['notificationFrequency']>;
}
export interface FormGroupCourseSelection {
  loop: FormControl<boolean>;
  phases: FormArray<FormGroup<FormGroupPhase>>;
}

export interface FormGroupPhase {
  course: FormControl<CourseGalleryItemDTO | null>;
}

@Injectable({
  providedIn: 'root',
})
export class LearningPlanBuilderDataService {
  public isLoading = false;
  public editedLearningPlanId: string | null;
  public topicAreas: Array<{ key: string; value: string }>;
  private readonly startOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  public formGroupSettings: FormGroup<FormGroupSettings> = this.createInitialFormGroupSettings();

  public formGroupCourseSelection: FormGroup<FormGroupCourseSelection> = this.createInitialFormGroupCourseSelection();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly learningPlanService: LearningPlanService,
    private readonly snackbarService: SnackbarService,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    this.fetchAvailableTopicAreas();
    this.setupTopicAreaChangeListener();
  }

  private fetchAvailableTopicAreas() {
    this.learningPlanService.getTopicAreas().subscribe({
      next: (response) => {
        this.topicAreas = response.topicAreas.map((topicArea) => ({ key: topicArea.name, value: topicArea.id }));
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  private setupTopicAreaChangeListener(): void {
    this.formGroupSettings.controls.topicAreaId.valueChanges.subscribe(() => {
      this.resetPhasesAndLoop();
    });
  }

  private resetPhasesAndLoop(): void {
    this.formGroupCourseSelection.controls.phases.clear();
    this.formGroupCourseSelection.controls.phases.push(this.createPhaseFormGroup());
    this.formGroupCourseSelection.controls.loop.setValue(true);
    this.formGroupCourseSelection.updateValueAndValidity();
  }

  private reset(): void {
    this.editedLearningPlanId = null;
    this.formGroupSettings = this.createInitialFormGroupSettings();
    this.formGroupCourseSelection = this.createInitialFormGroupCourseSelection();
  }

  private createInitialFormGroupSettings(): FormGroup<FormGroupSettings> {
    return this.formBuilder.group<FormGroupSettings>({
      learningPlanName: this.formBuilder.control(
        { value: '', disabled: this.isLoading },
        {
          updateOn: 'blur',
          validators: [Validators.required, Validators.maxLength(50), forbiddenCharactersValidator()],
        },
      ),
      topicAreaId: this.formBuilder.control(
        { value: null, disabled: this.isLoading },
        { updateOn: 'blur', validators: Validators.required },
      ),
      phaseFrequencyId: this.formBuilder.control(
        { value: 'year', disabled: true },
        { updateOn: 'blur', validators: Validators.required },
      ),
      startDate: this.formBuilder.control(
        { value: this.startOfCurrentMonth, disabled: true },
        { updateOn: 'blur', validators: Validators.required },
      ),
      notificationFrequency: this.formBuilder.control(
        { value: 'monthly', disabled: true },
        { updateOn: 'blur', validators: Validators.required },
      ),
    });
  }

  private createInitialFormGroupCourseSelection(): FormGroup<FormGroupCourseSelection> {
    return this.formBuilder.group<FormGroupCourseSelection>({
      phases: this.formBuilder.array([this.createPhaseFormGroup()]),
      loop: this.formBuilder.nonNullable.control({ value: true, disabled: this.isLoading }),
    });
  }

  private createPhaseFormGroup(value: CourseGalleryItemDTO | null = null): FormGroup<FormGroupPhase> {
    return this.formBuilder.group({
      course: this.formBuilder.control(
        { value, disabled: this.isLoading },
        { updateOn: 'blur', validators: Validators.required },
      ),
    });
  }

  public getPhasesArray(): FormArray<FormGroup<FormGroupPhase>> {
    return this.formGroupCourseSelection.controls.phases;
  }

  public addPhase(): void {
    this.getPhasesArray().push(this.createPhaseFormGroup());
    this.formGroupCourseSelection.updateValueAndValidity();
  }

  public setCourseId(index: number, value: CourseGalleryItemDTO | null): void {
    this.getPhasesArray().controls[index].controls.course.setValue(value);
    this.formGroupCourseSelection.updateValueAndValidity();
  }

  public removePhase(index: number): void {
    const phasesArray = this.getPhasesArray();
    if (phasesArray.length > 1) {
      phasesArray.removeAt(index, { emitEvent: true });
    }
    this.formGroupCourseSelection.updateValueAndValidity();
  }

  public setEditedLearningPlan(learningPlanInstance: LearningPlanInstanceDetailUiModel | null) {
    if (learningPlanInstance) {
      this.editedLearningPlanId = learningPlanInstance.id;
      this.formGroupSettings.controls.learningPlanName.setValue(learningPlanInstance.name);
      this.formGroupSettings.controls.phaseFrequencyId.setValue(learningPlanInstance.orderedPhases[0].duration);
      this.formGroupSettings.controls.startDate.setValue(new Date(learningPlanInstance.startAt));
      this.formGroupSettings.controls.topicAreaId.setValue(learningPlanInstance.topicArea?.id ?? null);
      this.formGroupCourseSelection.controls.phases = this.formBuilder.array(
        learningPlanInstance.orderedPhases.map(({ courseInstances }) =>
          this.createPhaseFormGroup({
            id: courseInstances[0].courseId,
            aboutUrl: courseInstances[0].aboutUrl ?? '',
            name: courseInstances[0].name,
            externalMoodleId: '',
            description: '',
          }),
        ),
      );
      const lastPhase = learningPlanInstance.orderedPhases.at(-1);
      const firstPhase = learningPlanInstance.orderedPhases.at(0);
      this.formGroupCourseSelection.controls.loop.setValue(
        !!firstPhase && !!lastPhase && lastPhase.nextPhaseId === firstPhase.id,
      );
    } else {
      this.reset();
    }
  }

  private _getLearningPlanData(): TypeLearningPlanInstancePOST | undefined {
    const { learningPlanName, topicAreaId, startDate, phaseFrequencyId } = this.formGroupSettings.getRawValue();
    const { phases, loop } = this.formGroupCourseSelection.getRawValue();

    if (!learningPlanName || !topicAreaId || !phaseFrequencyId || !startDate) {
      return undefined;
    }

    return {
      learningPlanInstance: {
        name: learningPlanName,
        topicAreaId: topicAreaId,
        startDate: startDate.toISOString(),
        looping: loop,
        phases: phases.reduce<TypePhase[]>((acc, { course }, index) => {
          if (course) {
            acc.push({
              name: `${index + 1}`,
              duration: phaseFrequencyId,
              courses: [course.id],
            });
          }
          return acc;
        }, []),
      },
    };
  }

  public createLearningPlanInstance() {
    if (this.isLoading) {
      return;
    }

    const learningPlanInstance = this._getLearningPlanData();

    if (!learningPlanInstance) {
      return;
    }

    this.isLoading = true;

    this.learningPlanService.createLearningPlanInstance(learningPlanInstance).subscribe({
      next: (response) => {
        this.store.dispatch(new GetCurrentUserData());
        this.isLoading = false;
        const learningPlanId = response.learningPlanInstance.id;
        const path = learningPlanId ? `/portal/learningPlans/${learningPlanId}` : `/portal/overview/`;
        this.reset();
        void this.router.navigate([path]);
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        this.isLoading = false;
      },
    });
  }

  public editLearningPlanInstance() {
    if (this.isLoading) {
      return;
    }

    const learningPlanInstance = this._getLearningPlanData();

    if (!learningPlanInstance || !this.editedLearningPlanId) {
      return;
    }

    this.isLoading = true;

    this.learningPlanService.editLearningPlanInstance(this.editedLearningPlanId, learningPlanInstance).subscribe({
      next: () => {
        this.store.dispatch(new GetCurrentUserData());
        this.isLoading = false;
        const learningPlanId = this.editedLearningPlanId;
        const path = learningPlanId ? `/portal/learningPlans/${learningPlanId}` : `/portal/overview/`;
        this.reset();
        void this.router.navigate([path]);
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        this.isLoading = false;
      },
    });
  }
}
