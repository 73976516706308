<div
  *transloco="let t"
  class="w-full max-w-fit overflow-hidden rounded-xl border border-grey-lightGrey bg-white"
>
  <app-arrow-wrapper [arrowChanges$]="arrowChanges$">
    <div class="flex">
      @for (phase of phases; track phase; let phaseIndex = $index) {
        <div class="flex w-[400px] flex-col">
          <div
            class="w-full border-b border-grey-lightGrey bg-grey-veryLightGrey p-3 text-center text-sm font-medium text-grey"
            [ngClass]="{
              'border-r border-grey-lightGrey': phaseIndex < phases.length - 1,
            }"
          >
            {{
              t(
                'portal.learningPlans.builder.stepper.settings.form.phaseFrequency.options.' +
                  phase.duration
              ) +
                ' ' +
                (phaseIndex + 1)
            }}
          </div>
          <div
            class="flex flex-row justify-center gap-4 p-4"
            [class]="{
              'pb-16': looping,
            }"
          >
            @for (
              courseInstance of phase.courseInstances;
              track courseInstance
            ) {
              <div
                class="element_with_arrowhead box flex flex-col rounded-lg border border-grey-lightGrey p-4 shadow-md"
                [class]="{
                  loop_enabled: looping && phases.length === phaseIndex + 1,
                }"
              >
                <div class="mt-auto flex justify-between">
                  <div class="flex items-center">
                    <mat-icon class="text-md mr-2 text-orange"
                      >description</mat-icon
                    >
                    <h3 class="text-sm font-semibold text-primary">
                      {{
                        t(
                          'portal.courses.courseTitleKeys.' +
                            courseInstance.name +
                            '.name'
                        )
                      }}
                    </h3>
                  </div>
                  @if (courseInstance.numberOfEnrolledStudents !== undefined) {
                    <div class="flex items-center">
                      <mat-icon class="items-center text-base text-grey"
                        >person</mat-icon
                      >
                      <span class="text-xs font-semibold">
                        {{ courseInstance.numberOfEnrolledStudents }}
                      </span>
                    </div>
                  }
                </div>
                <p class="min-h-24 py-2 text-sm text-grey">
                  {{
                    t(
                      'portal.courses.courseTitleKeys.' +
                        courseInstance.name +
                        '.description'
                    )
                  }}
                </p>
                <div class="mt-auto flex items-center justify-between">
                  @if (courseInstance.aboutUrl) {
                    <app-link
                      [description]="'Details'"
                      [clickAction]="onDetailsClick(courseInstance.aboutUrl)"
                    >
                      <mat-icon class="text-base font-semibold text-blue">
                        open_in_new
                      </mat-icon>
                    </app-link>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </app-arrow-wrapper>
</div>
