import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslocoDirective } from '@jsverse/transloco';
import { PhaseUiModel } from '../../models/LearningPlanInstanceDetailUIModel';
import { CommonModule } from '@angular/common';
import { LinkComponent } from '../../../../../shared/components/link/link.component';
import { ArrowWrapperComponent } from '../../../../../shared/components/arrow-wrapper/arrow-wrapper.component';
import { Observable } from 'rxjs';
import { LanguageService } from '../../../../../core/services/language.service';

@Component({
  selector: 'app-learning-plan-structure',
  standalone: true,
  imports: [MatIcon, TranslocoDirective, CommonModule, LinkComponent, ArrowWrapperComponent],
  templateUrl: './learning-plan-structure.component.html',
})
export class LearningPlanStructureComponent {
  @Input() phases: PhaseUiModel[] = [];
  @Input() arrowChanges$?: Observable<void>;
  @Input() looping = false;

  constructor(private readonly languageService: LanguageService) {}

  onDetailsClick(url: string) {
    return () => {
      const activeLang = this.languageService.getActiveLanguage();
      if (activeLang === 'en') {
        url = url.replace('/kurse', '/en/kurse');
      }
      window.open(url, '_blank');
    };
  }
}
