import { Component } from '@angular/core';
import { CourseInstanceOverviewBaseComponent } from '../../../base/CourseInstanceOverviewBaseComponent';
import { AsyncPipe } from '@angular/common';
import { CourseInstanceInfoCardComponent } from '../course-instance-info-card/course-instance-info-card.component';
import { CourseInstanceDTO } from '../../models/CourseDTO';
import { LearningPlanInstanceDTO } from '../../../learning-plan/models/LearningPlanDTO';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-learning-plan-course-instance-overview',
  standalone: true,
  imports: [AsyncPipe, CourseInstanceInfoCardComponent, MatIconModule, TranslocoModule, MatExpansionModule],
  templateUrl: './learning-plan-course-instance-overview.component.html',
  styleUrl: './learning-plan-course-instance-overview.component.css',
})
export class LearningPlanCourseInstanceOverviewComponent extends CourseInstanceOverviewBaseComponent {
  getGroupedOverviewItems(
    courseInstances: CourseInstanceDTO[],
    learningPlanInstances: LearningPlanInstanceDTO[],
  ): Array<{ courseInstances: CourseInstanceDTO[]; topicAreaName: string }> {
    return courseInstances
      .filter((courseInstance) => courseInstance.phase.start)
      .map((courseInstance) => ({
        ...courseInstance,
        course: {
          ...courseInstance.course,
          name:
            learningPlanInstances.find(
              (learningPlanInstance) => learningPlanInstance.id === courseInstance.phase.learningPlanInstanceId,
            )?.name ?? '',
          topicAreaName:
            learningPlanInstances.find(
              (learningPlanInstance) => learningPlanInstance.id === courseInstance.phase.learningPlanInstanceId,
            )?.topicArea.name ?? '',
        },
      }))
      .reduce((result: Array<{ courseInstances: CourseInstanceDTO[]; topicAreaName: string }>, courseInstance) => {
        const existingEntry = result.find((item) => item.topicAreaName === courseInstance.course.topicAreaName);

        if (existingEntry) {
          existingEntry.courseInstances.push(courseInstance);
        } else {
          result.push({
            courseInstances: [courseInstance],
            topicAreaName: courseInstance.course.topicAreaName,
          });
        }

        return result;
      }, []);
  }

  getIcon(topicAreaName: string): string {
    switch (topicAreaName) {
      case 'CyberSecurity':
        return 'security';
      case 'DataProtection':
        return 'lock';
      case 'OccupationalSafety':
        return 'construction';
      case 'Compliance':
        return 'check_circle';
      default:
        return '';
    }
  }
}
