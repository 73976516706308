<label class="inline-flex cursor-pointer items-center gap-3 py-2">
  <span class="text-sm font-medium">
    {{ label }}
  </span>
  <input
    type="checkbox"
    class="peer sr-only"
    [checked]="checked"
    (change)="onToggleChange()"
  />
  <div
    class="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-0.5 after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-checked:bg-blue-600 dark:peer-focus:ring-blue-800"
  ></div>
</label>
