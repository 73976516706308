import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, EMPTY, Observable, Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { CourseGalleryCardComponent } from '../course-gallery-card/course-gallery-card.component';
import { CourseGalleryItemDTO } from '../../models/LearningPlanDTO';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { CourseGalleryService } from '../../services/course-gallery.service';
import { MatIcon } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { LoadingComponent } from '../../../../../shared/components/loading/loading.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LearningPlanBuilderDataService } from '../../services/learning-plan-builder-data.service';

@Component({
  selector: 'app-learning-plan-course-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    CourseGalleryCardComponent,
    MatIcon,
    TranslocoModule,
    LoadingComponent,
    MatProgressSpinner,
  ],
  templateUrl: './learning-plan-course-selection-dialog.component.html',
})
export class LearningPlanCourseSelectionDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  courseGalleryRes$: Observable<CourseGalleryItemDTO[]>;
  public selectedTopicAreaName: string | null = null;
  public courses: CourseGalleryItemDTO[] = [];

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<LearningPlanCourseSelectionDialogComponent>,
    private readonly snackbarService: SnackbarService,
    private readonly courseGalleryService: CourseGalleryService,
    private readonly learningPlanDataService: LearningPlanBuilderDataService,
    @Inject(MAT_DIALOG_DATA) public data: { topicAreaId: string | null },
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.courseGalleryRes$ = this.courseGalleryService.getCourses(this.data.topicAreaId).pipe(
      catchError((error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        this.isLoading = false;
        return EMPTY;
      }),
    );
    this.courseGalleryRes$.subscribe((courses) => {
      this.courses = courses;
      this.courses = this.courses.filter((course) => ![44003, 33003, 22003].includes(Number(course.externalMoodleId)));
      this.courses = this.courses.sort((a, b) => Number(a.externalMoodleId) - Number(b.externalMoodleId));
      this.isLoading = false;
    });
    this.selectedTopicAreaName =
      this.learningPlanDataService.topicAreas.find((topic) => topic.value === this.data.topicAreaId)?.key ?? null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectCourse(course: CourseGalleryItemDTO) {
    this.dialogRef.close(course);
  }
}
