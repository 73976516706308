@if (isLoading) {
  <div
    class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900/50"
  >
    <div
      class="size-16 animate-spin rounded-full border-2 border-grey-lightGrey border-t-orange"
    ></div>
  </div>
} @else {
  <div class="flex flex-col pb-7 pl-12 pr-7 pt-10" *transloco="let t">
    <h2 class="mb-6 text-2xl font-semibold">
      {{
        t(
          'portal.learningPlans.builder.stepper.courseSelection.selectCourseButton'
        )
      }}
    </h2>
    <div class="mb-4 flex items-center">
      <mat-icon class="text-md mr-2 text-grey">storage</mat-icon>
      <h3 class="text-md mr-1 font-semibold text-grey">
        {{ t('portal.topicAreas.' + selectedTopicAreaName) }}
      </h3>
      <h3 class="text-md font-semibold text-grey">
        ({{ this.courses.length }})
      </h3>
    </div>
    <div class="flex flex-row flex-wrap gap-5">
      @for (course of courses; track course.name) {
        <app-course-gallery-card
          [course]="course"
          (courseClicked)="selectCourse(course)"
        ></app-course-gallery-card>
      }
    </div>
  </div>
}
