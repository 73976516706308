<div *transloco="let t" class="mt-10">
  <div class="mb-14">
    <p class="mb-5 text-lg font-medium text-blue-dark">
      {{ t('portal.learningPlans.builder.stepper.settings.headline') }}
    </p>
    <div class="flex flex-wrap gap-12">
      @for (field of displaySettings; track field) {
        <div>
          <p class="mb-2 text-sm font-light text-grey">
            {{ t(field.label) }}
          </p>
          <p class="text-sm font-semibold text-blue-dark">
            {{ field.value }}
          </p>
        </div>
      }
    </div>
  </div>
  <div class="relative w-full">
    <p class="mb-5 text-lg font-medium text-blue-dark">
      {{ t('portal.learningPlans.builder.stepper.courseSelection.headline') }}
    </p>
    @if (displayPhases$ | async; as displayPhases) {
      <app-learning-plan-structure
        [arrowChanges$]="arrowChanges$"
        [phases]="displayPhases"
        [looping]="dataService.formGroupCourseSelection.controls.loop.value"
      />
    }
  </div>
</div>
