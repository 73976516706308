import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.css',
})
export class BadgeComponent {
  // define more styles as soon as specified by the Design e.g. 'warning'|'info'
  @Input({ required: true }) text: string;
  @Input() type?: 'notification' | 'transparent' | 'warning';
  @Input() icon?: string;
  @Input() iconType: 'filled' | 'outlined' = 'filled';

  get matIconTypeClass() {
    switch (this.iconType) {
      case 'outlined':
        return 'material-icons-outlined';
      case 'filled':
      default:
        return '';
    }
  }

  get classes() {
    switch (this.type) {
      case 'warning':
        return 'bg-orange-middleLight text-orange';
      case 'transparent':
        return 'bg-transparent text-grey';
      case 'notification':
      case undefined:
      default:
        return 'bg-grey-lightGrey text-dark-blue';
    }
  }

  get dotClasses() {
    switch (this.type) {
      case 'warning':
        return 'bg-orange';
      case 'transparent':
      case 'notification':
      case undefined:
      default:
        return '';
    }
  }
}
