<button
  *transloco="let t"
  class="{{
    'flex w-full cursor-pointer items-center justify-center gap-2 rounded-lg border border-dashed border-grey ' +
      classes
  }}"
  (click)="handleButtonClick()"
>
  <mat-icon class="material-icons-outlined hvs-icon-lg !text-blue">
    add
  </mat-icon>
  <span class="whitespace-pre text-sm font-semibold text-blue">
    {{ label }}
  </span>
</button>
