import { Component, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFilterFn, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { merge, Subject, takeUntil } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDateFormats, provideNativeDateAdapter, MAT_NATIVE_DATE_FORMATS, DateAdapter } from '@angular/material/core';

export const SEP_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: {
      year: 'numeric',
      month: 'long',
      day: undefined,
    } as Intl.DateTimeFormatOptions,
  },
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'long',
      day: undefined,
    } as Intl.DateTimeFormatOptions,
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.css',
  providers: [provideNativeDateAdapter(SEP_DATE_FORMAT)],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoDirective,
    MatIcon,
    MatError,
    MatTooltip,
  ],
  standalone: true,
})
export class DatepickerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) control: FormControl<Date | null>;
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() helpText = '';
  @Input() minDate: Date | null;
  @Input() maxDate: Date | null;
  @Input() allowedMonths: number[] | null;

  private readonly destroy$: Subject<void> = new Subject();

  errorMessage = signal('');

  private readonly translocoService = inject(TranslocoService);
  private readonly dateAdapter = inject(DateAdapter<Date>);

  constructor() {
    this.setLocale();
  }

  private setLocale() {
    const currentLang = this.translocoService.getActiveLang();
    this.dateAdapter.setLocale(currentLang);
  }

  ngOnInit() {
    merge(
      this.control.statusChanges.pipe(startWith(this.control.status)).pipe(takeUntil(this.destroy$)),
      this.control.valueChanges.pipe(startWith(this.control.value)).pipe(takeUntil(this.destroy$)),
    ).subscribe(this.updateErrorMessage.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateErrorMessage() {
    const { errors } = this.control;
    if (errors) {
      const [firstErrorKey] = Object.keys(errors);
      this.errorMessage.set(firstErrorKey || 'default');
    } else {
      this.errorMessage.set('');
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Date, datepicker: MatDatepicker<Date>) {
    const ctrlValue = this.control.value ?? new Date();
    ctrlValue.setFullYear(normalizedMonthAndYear.getFullYear(), normalizedMonthAndYear.getMonth(), 1);
    this.control.setValue(ctrlValue);
    datepicker.close();
  }

  dateFilter: DateFilterFn<Date | null> = (date) => {
    if (!date) {
      return false;
    }
    if (!this.allowedMonths) {
      return true;
    }
    return this.allowedMonths.includes(date.getMonth());
  };
}
