import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { merge, Subject, takeUntil } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatIcon,
    MatTooltip,
    MatFormFieldModule,
    MatSelectModule,
    MatError,
    TranslocoDirective,
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
})
export class SelectComponent implements OnInit, OnDestroy {
  @Input({ required: true }) control: FormControl = new FormControl();
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() translocoPrefix?: string;
  @Input() helpText = '';
  @Input() options: Array<{ value: string; key: string }> = [];

  private readonly destroy$: Subject<void> = new Subject();

  errorMessage = signal('');

  constructor(private readonly translocoService: TranslocoService) {}

  ngOnInit() {
    merge(
      this.control.statusChanges.pipe(startWith(this.control.status)).pipe(takeUntil(this.destroy$)),
      this.control.valueChanges.pipe(startWith(this.control.value)).pipe(takeUntil(this.destroy$)),
    ).subscribe(this.updateErrorMessage.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLabel(key: string): string {
    return this.translocoService.translate(`${this.translocoPrefix}.${key}`);
  }

  updateErrorMessage() {
    const { errors } = this.control;
    if (errors) {
      const [firstErrorKey] = Object.keys(errors);
      this.errorMessage.set(firstErrorKey || 'default');
    } else {
      this.errorMessage.set('');
    }
  }
}
