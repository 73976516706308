<div
  class="flex w-full flex-col items-center gap-6 rounded-lg bg-grey-lightGrey px-8 pb-2 pt-6"
>
  @if (displayRemovePhaseButton) {
    <mat-icon
      class="material-icons-outlined hvs-icon-base absolute right-6 top-6 cursor-pointer"
      (click)="onButtonClick()"
    >
      close
    </mat-icon>
  }
  <span class="text-sm font-medium text-grey">{{ name }}</span>
  <ng-content />
</div>
