import { Component, OnDestroy, OnInit } from '@angular/core';
import { LearningPlanBuilderDataService } from '../../services/learning-plan-builder-data.service';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { startWith } from 'rxjs/operators';
import { LearningPlanStructureComponent } from '../learning-plan-structure/learning-plan-structure.component';
import { PhaseUiModel } from '../../models/LearningPlanInstanceDetailUIModel';
import { AsyncPipe } from '@angular/common';

interface DisplaySettings {
  label: string;
  value: string;
}

@Component({
  selector: 'app-learning-plan-builder-overview',
  standalone: true,
  imports: [TranslocoDirective, LearningPlanStructureComponent, AsyncPipe],
  templateUrl: './learning-plan-builder-overview.component.html',
})
export class LearningPlanBuilderOverviewComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  protected displaySettings: DisplaySettings[] = [];
  protected displayPhases$: Observable<PhaseUiModel[]>;
  protected arrowChanges$: Observable<void>;
  constructor(
    readonly translocoService: TranslocoService,
    readonly dataService: LearningPlanBuilderDataService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.dataService.formGroupSettings.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(this.dataService.formGroupSettings.getRawValue()),
        map((rawSettings) => [
          {
            label: 'portal.learningPlans.builder.stepper.settings.form.learningPlanName.label',
            value: rawSettings.learningPlanName ?? '',
          },
          {
            label: 'portal.learningPlans.builder.stepper.settings.form.phaseFrequency.label',
            value: this.getPhaseFrequency(rawSettings.phaseFrequencyId ?? 'year'),
          },
          {
            label: 'portal.learningPlans.builder.stepper.settings.form.startDate.label',
            value: this.transformDateForDisplay(
              rawSettings.startDate ?? new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            ),
          },
        ]),
      )
      .subscribe((value) => (this.displaySettings = value));
    this.displayPhases$ = this.dataService.formGroupCourseSelection.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.dataService.formGroupCourseSelection.getRawValue()),
      map((rawCourseSelection) =>
        rawCourseSelection.phases
          ? rawCourseSelection.phases.map((phase, index) => ({
              id: `${index}`,
              name: `${index}`,
              duration: this.dataService.formGroupSettings.controls.phaseFrequencyId.value ?? '',
              nextPhaseId: null,
              courseInstances: [
                {
                  id: '',
                  courseId: phase.course?.id ?? '',
                  name: phase.course?.name ?? '',
                  aboutUrl: phase.course?.aboutUrl ?? '',
                },
              ],
            }))
          : [],
      ),
    );
    this.arrowChanges$ = this.displayPhases$.pipe(map(() => void 0));
  }

  transformDateForDisplay(date?: Date | null) {
    const lang = this.translocoService.getActiveLang();
    return date
      ? date.toLocaleDateString(lang === 'de' ? 'de-DE' : 'en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: undefined,
          minute: undefined,
          second: undefined,
        })
      : '';
  }

  getPhaseFrequency(phaseFrequencyId?: string | null) {
    return phaseFrequencyId
      ? this.translocoService.translate(
          `portal.learningPlans.builder.stepper.settings.form.phaseFrequency.options.${phaseFrequencyId}`,
        )
      : '';
  }
}
