import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-card-button',
  standalone: true,
  imports: [MatIcon, TranslocoDirective],
  templateUrl: './card-button.component.html',
})
export class CardButtonComponent {
  @Input() label: string;
  @Input() classes: string;
  @Output() buttonClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  handleButtonClick() {
    this.buttonClickedEvent.emit();
  }
}
