import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { LearningPlanDataService } from '../../../domains/academy/learning-plan/services/learning-plan-data.service';
import { LearningPlanEnrolledStudentTableComponent } from '../../../domains/academy/learning-plan/components/learning-plan-enrolled-student-table/learning-plan-enrolled-student-table.component';
import { LearningPlanService } from '../../../domains/academy/learning-plan/services/learning-plan.service';
import { CustomPortalError } from '../../../core/errors/models/CustomPortalError';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { AsyncPipe } from '@angular/common';
import { LearningPlanStructureComponent } from '../../../domains/academy/learning-plan/components/learning-plan-structure/learning-plan-structure.component';
import {
  LearningPlanInstanceDetailUiModel,
  PhaseUiModel,
} from '../../../domains/academy/learning-plan/models/LearningPlanInstanceDetailUIModel';
import { toLearningPlanInstanceDetailUiModel } from '../../../domains/academy/learning-plan/mappers/dto-mappers';
import { LearningPlanStatisticsComponent } from '../../../domains/academy/learning-plan/components/learning-plan-statistics/learning-plan-statistics.component';
import { EnrollmentNotificationService } from '../../../core/services/enrollment-notification.service';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';
import { TranslocoWithFallbackPipe } from '../../../core/pipe/transloco-with-fallback.pipe';
import { LearningPlanBuilderDataService } from '../../../domains/academy/learning-plan/services/learning-plan-builder-data.service';
import { LearningPlanStatusComponent } from '../../../domains/academy/learning-plan/components/learning-plan-status/learning-plan-status.component';
import { LearningPlanStatus } from '../../../domains/academy/learning-plan/models/LearningPlanDTO';

@Component({
  selector: 'app-learning-plan-detail',
  standalone: true,
  imports: [
    MatTabsModule,
    TranslocoDirective,
    MatIcon,
    RouterOutlet,
    LearningPlanEnrolledStudentTableComponent,
    AsyncPipe,
    LearningPlanStructureComponent,
    LearningPlanStatisticsComponent,
    TranslocoWithFallbackPipe,
    TranslocoPipe,
    LearningPlanStatusComponent,
  ],
  animations: [easeInAnimationFast],
  templateUrl: './learning-plan-detail.component.html',
  styleUrl: './learning-plan-detail.component.css',
})
export class LearningPlanDetailComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  learningPlanInstanceDetails$: BehaviorSubject<LearningPlanInstanceDetailUiModel | null> =
    new BehaviorSubject<LearningPlanInstanceDetailUiModel | null>(null);
  selectedTabIndex = 0;
  private learningPlanId: string | undefined;
  protected isLooping = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly learningPlanDataService: LearningPlanDataService,
    private readonly learningPlanService: LearningPlanService,
    private readonly snackbarService: SnackbarService,
    private readonly enrollmentNotificationService: EnrollmentNotificationService,
    private readonly learningPlanBuilderDataService: LearningPlanBuilderDataService,
  ) {}

  ngOnInit(): void {
    this.observeLearningPlanInstanceIdInUrlAndFetch();
    this.enrollmentNotificationService
      .getEnrollmentChanged()
      .pipe(takeUntil(this.destroy$))
      .subscribe((enrollmentChanged) => {
        this.learningPlanId = this.learningPlanDataService.getLearningPlanInstanceId();
        if (enrollmentChanged && this.learningPlanId) {
          this.getLearningPlanDetails(this.learningPlanId);
        }
      });
  }

  private observeLearningPlanInstanceIdInUrlAndFetch(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.learningPlanInstanceDetails$.next(null);
        this.selectedTabIndex = 0;
        this.learningPlanDataService.setLearningPlanInstanceId(id);
        this.getLearningPlanDetails(id);
      }
    });
  }

  getLearningPlanDetails(id: string) {
    this.learningPlanService.getLearningPlanInstanceDetails(id).subscribe({
      next: (res) => {
        this.learningPlanDataService.setLearningPlaneName(res.learningPlanInstance.name);
        this.learningPlanInstanceDetails$.next(toLearningPlanInstanceDetailUiModel(res.learningPlanInstance));
        this.isLooping = this.getIsLooping(this.learningPlanInstanceDetails$.value?.orderedPhases);
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  canEdit(status: LearningPlanStatus) {
    return LearningPlanStatus.SCHEDULED === status;
  }

  getIsLooping(orderedPhases?: PhaseUiModel[]) {
    if (orderedPhases) {
      const firstPhase = orderedPhases.at(0);
      const lastPhase = orderedPhases.at(orderedPhases.length - 1);
      if (lastPhase && firstPhase) {
        return lastPhase.nextPhaseId === firstPhase.id;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onEnrollButtonClick() {
    void this.router.navigate(['dialog'], {
      relativeTo: this.route,
      queryParams: { step: 'enroll' },
    });
  }

  onEditButtonClick() {
    this.learningPlanBuilderDataService.setEditedLearningPlan(this.learningPlanInstanceDetails$.value);
    if (this.learningPlanInstanceDetails$.value) {
      void this.router.navigate([`edit`], { relativeTo: this.route });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
