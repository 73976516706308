import { Directive } from '@angular/core';
import { DialogModel } from '../../../shared/components/dialog/dialog';
import { MatDialog } from '@angular/material/dialog';
import { HashMap } from '@jsverse/transloco';
import { LanguageService } from '../../../core/services/language.service';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnrolledStudentBaseUIModel } from './EnrolledStudentBaseUIModel';

@Directive()
export abstract class EnrolledStudentTableBaseComponent {
  constructor(
    protected dialog: MatDialog,
    protected languageService: LanguageService,
    protected analyticsService: AnalyticsService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {}

  showCourseEnrollmentDialog() {
    this.analyticsService.track('add_participants_clicked');
    void this.router.navigate(['dialog'], { relativeTo: this.route, queryParams: { step: 'enroll' } });
  }

  showSendReminderConfirmationDialog(
    targetedStudents: EnrolledStudentBaseUIModel[],
    sendReminder: (targetedStudents: EnrolledStudentBaseUIModel[]) => void,
  ) {
    const reminderSubject =
      targetedStudents.length === 1
        ? targetedStudents[0].username
        : this.getTranslation('courses.reminders.allSelectedText');
    const reminderDialog = new DialogModel(this.dialog);
    reminderDialog.closable = true;
    reminderDialog.headline = this.getTranslation('courses.reminders.sendReminderConfirmationHeadline');
    reminderDialog.description = this.getTranslation('courses.reminders.sendReminderConfirmationText', {
      reminderSubject,
    });
    reminderDialog.buttonRowData = {
      confirmButtonText: this.getTranslation('courses.reminders.sendReminderButtonText'),
      confirmButtonAction: () => {
        sendReminder(targetedStudents);
        reminderDialog.closeDialog();
      },
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };
    reminderDialog.openDialog();
  }

  showUnenrollmentConfirmationDialog(targetedStudents: EnrolledStudentBaseUIModel[], confirmButtonAction: () => void) {
    const unenrollmentSubject =
      targetedStudents.length === 1
        ? targetedStudents[0].username
        : this.getTranslation('courses.unenrollment.allSelectedText');

    const unenrollmentConfirmationDialog = new DialogModel(this.dialog);
    unenrollmentConfirmationDialog.closable = true;
    unenrollmentConfirmationDialog.headline = this.getTranslation(
      'courses.unenrollment.unenrollmentConfirmationHeadline',
    );
    unenrollmentConfirmationDialog.description = this.getTranslation(
      'courses.unenrollment.unenrollmentConfirmationText',
      { unenrollmentSubject },
    );
    unenrollmentConfirmationDialog.buttonRowData = {
      confirmButtonText: this.getTranslation('courses.unenrollment.unenrollButtonText'),
      confirmButtonAction: () => {
        confirmButtonAction();
        unenrollmentConfirmationDialog.closeDialog();
      },
      declineButtonText: this.languageService.translateOnRuntime('general.abort'),
    };

    unenrollmentConfirmationDialog.openDialog();
  }

  openFile(data: Blob, fileName: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  getTranslation(key: string, params?: HashMap): string {
    return this.languageService.translateOnRuntime('portal.' + key, params);
  }
}
