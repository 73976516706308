import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { SelectComponent } from '../../../../../shared/components/select/select.component';
import {
  LearningPlanBuilderDataService,
  TypeLearningPlanSettings,
} from '../../services/learning-plan-builder-data.service';
import { DatepickerComponent } from '../../../../../shared/components/datepicker/datepicker.component';
import { InputFieldComponent } from '../../../../../shared/components/input-field/input-field.component';
import { startWith } from 'rxjs/operators';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-learning-plan-builder-settings',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoDirective, SelectComponent, DatepickerComponent, InputFieldComponent],
  templateUrl: './learning-plan-builder-settings.component.html',
})
export class LearningPlanBuilderSettingsComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<void> = new Subject();
  readonly optionsNotificationFrequency = [
    {
      value: 'monthly',
      key: 'monthly',
    },
  ];
  readonly optionsPhaseFrequency = [
    {
      value: 'year',
      key: 'year',
    },
    {
      value: 'quarter',
      key: 'quarter',
    },
    {
      value: 'month',
      key: 'month',
    },
  ];

  today: Date = new Date();
  minDate: Date | null = null;
  maxDate: Date | null = new Date(this.today.getFullYear() + 5, this.today.getMonth(), this.today.getDate());
  allowedMonths: number[] | null;

  constructor(protected readonly dataService: LearningPlanBuilderDataService) {}

  ngOnInit(): void {
    this.observePhaseFrequencyId();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  observePhaseFrequencyId() {
    const { phaseFrequencyId } = this.dataService.formGroupSettings.controls;
    phaseFrequencyId.valueChanges
      .pipe(startWith(phaseFrequencyId.value))
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.setDateRestrictions);
  }

  setDateRestrictions = (frequency?: TypeLearningPlanSettings['phaseFrequencyId']) => {
    if (!frequency) {
      this.minDate = null;
      return;
    }
    switch (frequency) {
      case 'year':
        this.minDate = new Date(this.today.getFullYear() - 1, this.today.getMonth() + 1, 1);
        this.allowedMonths = null;
        break;
      case 'quarter':
        this.minDate = new Date(this.today.getFullYear(), this.today.getMonth() - 2, 1);
        this.allowedMonths = [0, 3, 6, 9];
        break;
      case 'month':
        this.minDate = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
        this.allowedMonths = null;
        break;
      default:
        this.minDate = null;
        this.allowedMonths = null;
    }
  };
}
