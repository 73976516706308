import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimaryButtonComponent } from '../../../../../shared/components/primary-button/primary-button.component';
import { CourseGalleryItemDTO } from '../../models/LearningPlanDTO';
import { SecondaryButtonComponent } from '../../../../../shared/components/secondary-button/secondary-button.component';
import { MatIcon } from '@angular/material/icon';
import { ButtonType } from '../../../../../shared/components/primary-button/ButtonType';
import { TranslocoModule } from '@jsverse/transloco';
import { LanguageService } from '../../../../../core/services/language.service';

@Component({
  selector: 'app-course-gallery-card',
  standalone: true,
  imports: [PrimaryButtonComponent, SecondaryButtonComponent, MatIcon, TranslocoModule],
  templateUrl: './course-gallery-card.component.html',
})
export class CourseGalleryCardComponent {
  @Input({ required: true }) course: CourseGalleryItemDTO;
  @Output() courseClicked: EventEmitter<void> = new EventEmitter<void>();
  protected readonly buttonType = ButtonType;

  constructor(private readonly languageService: LanguageService) {}

  goToExternalCourseInfoLink(externalLink: string): void {
    const activeLang = this.languageService.getActiveLanguage();
    if (activeLang === 'en') {
      externalLink = externalLink.replace('/kurse', '/en/kurse');
    }
    window.open(externalLink, '_blank');
  }

  addCourseToLearningPlan(): void {
    this.courseClicked.emit();
  }
}
