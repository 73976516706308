@let courseInstances = (courseInstancesRes$ | async)?.courseInstances;
@let learningPlanInstances =
  (learningPlanInstancesRes$ | async)?.learningPlanInstances;

<div class="mt-2 flex flex-col flex-wrap" *transloco="let t">
  @if (courseInstances && learningPlanInstances) {
    @for (
      groupedCourseInstance of getGroupedOverviewItems(
        courseInstances,
        learningPlanInstances
      );
      track groupedCourseInstance.topicAreaName;
      let i = $index
    ) {
      <mat-expansion-panel
        [expanded]="i === 0"
        class="mr-8 mb-12 expansion-panel-arrow-color"
      >
        <mat-expansion-panel-header
          class="flex flex-col gap-2 text-grey text-sm items-center"
        >
          <mat-icon
            class="text-sm items-center justify-center"
            style="display: inline-flex"
            >{{ getIcon(groupedCourseInstance.topicAreaName) }}</mat-icon
          >
          {{ t('portal.topicAreas.' + groupedCourseInstance.topicAreaName) }}
          ({{ groupedCourseInstance.courseInstances.length }})
        </mat-expansion-panel-header>
        <div class="flex flex-row flex-wrap">
          @for (
            courseInstance of groupedCourseInstance.courseInstances;
            track courseInstance.id;
            let j = $index
          ) {
            <div class="mr-2 mt-4" @easeInFast>
              <app-course-instance-info-card
                class="flex h-full"
                [courseInstance]="courseInstance"
                [attr.id]="i === 0 && j === 0 ? 'ProductFruitsCard' : null"
                [isFirstCard]="i === 0 && j === 0"
              ></app-course-instance-info-card>
            </div>
          }
        </div>
      </mat-expansion-panel>
    }
  }
</div>
