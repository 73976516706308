<div *transloco="let t">
  <div class="mx-8 mt-20">
    <div class="mb-5 flex justify-between gap-2">
      <h1 class="text-3xl font-semibold">
        {{
          dataService.editedLearningPlanId
            ? t('portal.learningPlans.builder.headlineEditing')
            : t('portal.learningPlans.builder.headline')
        }}
      </h1>
      <div class="flex gap-2">
        <app-secondary-button
          [text]="t('portal.learningPlans.builder.previousStepButton')"
          [hidden]="isPreviousButtonHidden"
          (buttonClicked)="onPreviousButtonClicked()"
        />
        @if (showNextButton) {
          <app-primary-button
            [text]="t('portal.learningPlans.builder.nextStepButton')"
            [disabled]="isNextButtonDisabled"
            (buttonClicked)="onNextButtonClicked()"
          />
        } @else {
          <app-primary-button
            [text]="
              dataService.editedLearningPlanId
                ? t('portal.learningPlans.builder.editLearningPlanButton')
                : t('portal.learningPlans.builder.createLearningPlanButton')
            "
            [loading]="dataService.isLoading"
            (buttonClicked)="onCompleteLearningPlanClicked()"
          />
        }
      </div>
    </div>
    <mat-horizontal-stepper
      class="learning-plan-builder__stepper mat-horizontal-stepper w-full items-center"
      *transloco="let t"
      [linear]="true"
      [selectedIndex]="selectedIndex"
      (selectedIndexChange)="onSelectedIndexChange($event)"
    >
      @for (step of steps; track step) {
        @if (step.control) {
          <mat-step
            [label]="getStepLabel(step.key)"
            class="mb-10"
            [stepControl]="step.control"
          >
            <ng-container [ngComponentOutlet]="step.component" />
          </mat-step>
        } @else {
          <mat-step [label]="getStepLabel(step.key)" class="mb-10">
            <ng-container [ngComponentOutlet]="step.component" />
          </mat-step>
        }
      }
    </mat-horizontal-stepper>
  </div>
</div>
