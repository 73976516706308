import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { UserService } from '../store/current-user/services/user.service';
import { AuthService } from '../services/auth.service'; // Import AuthService for isSepAdmin

enum DetailsPagePaths {
  COURSES = 'courses/:id',
  LEARNING_PLANS = 'learningPlans/:id',
  LEARNING_PLANS_EDIT = 'learningPlans/:id/edit',
  LEARNING_PLANS_BUILDER = 'learningPlans/builder',
}

export const detailsPageGuard: CanActivateFn = (route) => {
  const msalBroadcastService = inject(MsalBroadcastService);
  const userService = inject(UserService);
  const authService = inject(AuthService);
  const router = inject(Router);

  return msalBroadcastService.inProgress$.pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    switchMap(() =>
      from(authService.isSepAdmin()).pipe(
        switchMap((isAdmin) => {
          if (isAdmin) {
            return of(true); // Admins always have access
          }

          // Fetch user data if not an admin
          return userService.getUserData().pipe(
            switchMap((userData) => {
              const [subscription] = userData.user.organization.subscriptions;
              const { isBoundToLearningPlans } = subscription;
              const path = route.routeConfig?.path;
              let hasRouteAccess = false;

              if (
                path === DetailsPagePaths.LEARNING_PLANS ||
                path === DetailsPagePaths.LEARNING_PLANS_BUILDER ||
                path === DetailsPagePaths.LEARNING_PLANS_EDIT
              ) {
                hasRouteAccess = isBoundToLearningPlans;
              } else if (path === DetailsPagePaths.COURSES) {
                hasRouteAccess = !isBoundToLearningPlans;
              }

              if (!hasRouteAccess) {
                void router.navigate(['/']);
              }

              return of(hasRouteAccess);
            }),
            catchError((error) => {
              console.error('Error acquiring user data:', error);
              void router.navigate(['/']);
              return of(false); // Return false if there's an error
            }),
          );
        }),
        catchError((error) => {
          console.error('Error checking admin status:', error);
          void router.navigate(['/']);
          return of(false);
        }),
      ),
    ),
  );
};
