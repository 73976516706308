import { Directive, OnInit } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { CourseInstancesResponseDTO } from '../course/models/CourseDTO';
import { CourseService } from '../course/services/course.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { CustomPortalError } from '../../../core/errors/models/CustomPortalError';
import { LearningPlanInstancesResponseDTO } from '../learning-plan/models/LearningPlanDTO';
import { LearningPlanService } from '../learning-plan/services/learning-plan.service';

@Directive()
export abstract class CourseInstanceOverviewBaseComponent implements OnInit {
  courseInstancesRes$: Observable<CourseInstancesResponseDTO>;
  learningPlanInstancesRes$: Observable<LearningPlanInstancesResponseDTO>;

  constructor(
    private readonly courseService: CourseService,
    private readonly snackbarService: SnackbarService,
    private readonly learningPlanService: LearningPlanService,
  ) {}

  ngOnInit(): void {
    this.courseInstancesRes$ = this.courseService.getCourseInstances().pipe(
      catchError((error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        return EMPTY;
      }),
    );
    this.learningPlanInstancesRes$ = this.learningPlanService.getLearningPlanInstances().pipe(
      catchError((error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        return EMPTY;
      }),
    );
  }
}
