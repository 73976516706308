<ng-template #userCard let-user>
  <div class="my-4">
    <app-user-card
      [username]="user.username"
      [email]="user.email"
      [highlightUsername]="false"
    />
  </div>
</ng-template>

<ng-template #editUserFormTemplate>
  @if (targetedUser) {
    <app-edit-user-form
      [userData]="targetedUser"
      (formStatusChanged)="onEditUserFormStatusChanged($event)"
    ></app-edit-user-form>
  }
</ng-template>

<ng-template #licenseBadge let-el>
  @switch (el.license?.status) {
    @case (licenseStatus.RESERVED) {
      <div class="flex items-center space-x-2" *transloco="let t">
        <app-badge
          icon="lock_open"
          [iconType]="'outlined'"
          [text]="t('portal.users.table.data.licenseStatusUnused')"
        ></app-badge>
        <mat-icon
          class="cursor-pointer text-base text-grey"
          [matTooltip]="t('portal.users.table.actions.unassignLicenseTooltip')"
          matTooltipPosition="above"
          matTooltipClass="tooltip-above"
          (click)="showLicenseUnassignmentConfirmationDialog(el)"
        >
          close
        </mat-icon>
      </div>
    }
    @case (licenseStatus.CONSUMED) {
      <div class="opacity-50">
        <app-badge
          *transloco="let t"
          icon="lock"
          [iconType]="'outlined'"
          [text]="t('portal.users.table.data.licenseStatusUsed')"
        ></app-badge>
      </div>
    }
    @case (null) {
      <p>-</p>
    }
  }
</ng-template>

<app-sep-table
  *transloco="let t"
  [columns]="columns"
  [rowActions]="rowActions"
  [dataSource]="users"
  [name]="t('portal.users.table.usersHeadline')"
  [noDataText]="t('portal.users.table.noDataTableText')"
  [hasBulkActionButton]="false"
  [hasCheckbox]="false"
  [primaryButtonTitle]="t('portal.users.table.userCreationButtonText')"
  (primaryButtonClickedEvent)="showUserCreationDialog()"
></app-sep-table>
