<div class="mb-2 flex items-center gap-1">
  @if (label) {
    <mat-label class="text-sm font-medium">{{ label }}</mat-label>
  }
  @if (helpText) {
    <mat-icon
      class="material-icons-outlined hvs-icon-base cursor-help"
      [matTooltip]="helpText"
      matTooltipPosition="above"
      matTooltipClass="tooltip-above"
    >
      help_outline
    </mat-icon>
  }
</div>
<mat-form-field
  *transloco="let t"
  class="select__form-field w-full"
  appearance="outline"
>
  <mat-select [formControl]="control" [placeholder]="placeholder">
    @for (option of options; track option.value) {
      <mat-option [value]="option.value">
        {{ getLabel(option.key) }}
      </mat-option>
    }
  </mat-select>
  <mat-icon matSuffix>expand_more</mat-icon>
  @if (control.invalid && errorMessage()) {
    <mat-error class="text-sm">
      {{ t('general.inputValidation.' + errorMessage()) }}
    </mat-error>
  }
</mat-form-field>
