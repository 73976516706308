import { Component, Input } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { LearningPlanStatus } from '../../models/LearningPlanDTO';

@Component({
  selector: 'app-learning-plan-status',
  standalone: true,
  imports: [MatChip, TranslocoDirective, MatTooltip, NgClass],
  templateUrl: './learning-plan-status.component.html',
})
export class LearningPlanStatusComponent {
  @Input() status: LearningPlanStatus;

  get getBgColorClass() {
    if (this.status === LearningPlanStatus.ACTIVE) {
      return '!bg-blue-light';
    }
    return '!bg-orange-light'; // SCHEDULED
  }
}
