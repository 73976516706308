export enum ErrorType {
  //Client Side Errors
  CLIENT_SIDE_ERROR = 'CLIENT_SIDE_ERROR',

  //General Http Errors
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  THIRD_PARTY_SERVICE_UNAVAILABLE = 'THIRD_PARTY_SERVICE_UNAVAILABLE',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',

  //Custom Errors
  VALIDATION_EXCEPTION = 'VALIDATION_EXCEPTION',
  NOT_ENOUGH_LICENSES_AVAILABLE = 'NOT_ENOUGH_LICENSES_AVAILABLE',
  LICENSE_ALREADY_CONSUMED = 'LICENSE_ALREADY_CONSUMED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  COURSE_NOT_IN_TOPIC_AREA = 'COURSE_NOT_IN_TOPIC_AREA',
}
