<form
  *transloco="let t"
  [formGroup]="dataService.formGroupSettings"
  class="mt-10 flex max-w-96 flex-col gap-y-2"
  role="form"
>
  <app-input-field
    [control]="dataService.formGroupSettings.controls.learningPlanName"
    [label]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.learningPlanName.label'
      )
    "
    [placeholder]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.learningPlanName.placeholder'
      )
    "
  />
  <app-select
    [control]="dataService.formGroupSettings.controls.topicAreaId"
    [label]="
      t('portal.learningPlans.builder.stepper.settings.form.topicArea.label')
    "
    [placeholder]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.topicArea.placeholder'
      )
    "
    [translocoPrefix]="
      'portal.learningPlans.builder.stepper.settings.form.topicArea.options'
    "
    [options]="dataService.topicAreas"
  />
  <app-select
    [control]="dataService.formGroupSettings.controls.phaseFrequencyId"
    [label]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.phaseFrequency.label'
      )
    "
    [placeholder]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.phaseFrequency.placeholder'
      )
    "
    [helpText]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.phaseFrequency.help'
      )
    "
    [translocoPrefix]="
      'portal.learningPlans.builder.stepper.settings.form.phaseFrequency.options'
    "
    [options]="optionsPhaseFrequency"
  />
  <app-datepicker
    [control]="dataService.formGroupSettings.controls.startDate"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [label]="
      t('portal.learningPlans.builder.stepper.settings.form.startDate.label')
    "
    [helpText]="
      t('portal.learningPlans.builder.stepper.settings.form.startDate.help')
    "
    [placeholder]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.startDate.placeholder'
      )
    "
    [allowedMonths]="allowedMonths"
  />
  <app-select
    [control]="dataService.formGroupSettings.controls.notificationFrequency"
    [label]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.notificationFrequency.label'
      )
    "
    [placeholder]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.notificationFrequency.placeholder'
      )
    "
    [helpText]="
      t(
        'portal.learningPlans.builder.stepper.settings.form.notificationFrequency.help'
      )
    "
    [translocoPrefix]="
      'portal.learningPlans.builder.stepper.settings.form.notificationFrequency.options'
    "
    [options]="optionsNotificationFrequency"
  />
</form>
