<div class="mx-8 mt-20" @easeInFast *transloco="let t">
  @if (subscription$ | async; as subscription) {
    <div class="flex justify-between gap-2">
      <h1 class="text-3xl font-semibold">
        {{ t('portal.courses.overview.headline') }}
      </h1>
      @if (subscription.isBoundToLearningPlans) {
        <app-primary-button
          [text]="t('portal.courses.overview.createLearningPlan')"
          [type]="buttonType.LEADING_ADD_ICON"
          (buttonClicked)="onButtonClicked()"
        />
      }
    </div>

    @if (subscription.isBoundToLearningPlans) {
      <app-learning-plan-course-instance-overview />
    } @else {
      <app-course-instances-overview />
    }
  }
</div>
