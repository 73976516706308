import { Component } from '@angular/core';
import { LearningPlanBuilderDataService } from '../../services/learning-plan-builder-data.service';
import { PhaseCardComponent } from '../phase-card/phase-card.component';
import { LoopToggleComponent } from '../loop-toggle/loop-toggle.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { CardButtonComponent } from '../../../../../shared/components/card-button/card-button.component';
import { ArrowWrapperComponent } from '../../../../../shared/components/arrow-wrapper/arrow-wrapper.component';
import { map, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { CourseSelectComponent } from '../../../../../shared/components/course-select/course-select.component';
import { LearningPlanCourseSelectionDialogComponent } from '../learning-plan-course-selection-dialog/learning-plan-course-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CourseGalleryItemDTO } from '../../models/LearningPlanDTO';

@Component({
  selector: 'app-learning-plan-builder-course-selection',
  standalone: true,
  imports: [
    PhaseCardComponent,
    LoopToggleComponent,
    ReactiveFormsModule,
    CardButtonComponent,
    TranslocoDirective,
    ArrowWrapperComponent,
    CourseSelectComponent,
  ],
  templateUrl: './learning-plan-builder-course-selection.component.html',
})
export class LearningPlanBuilderCourseSelectionComponent {
  constructor(
    protected readonly dataService: LearningPlanBuilderDataService,
    private readonly transloco: TranslocoService,
    public dialog: MatDialog,
  ) {}

  getObservablePhases(): Observable<void> {
    return this.dataService.getPhasesArray().valueChanges.pipe(
      startWith(this.dataService.getPhasesArray().value),
      map(() => void 0),
    );
  }

  onCourseSelectorButtonClickedEvent(index: number) {
    const dialogRef = this.dialog.open(LearningPlanCourseSelectionDialogComponent, {
      data: {
        autoFocus: true,
        width: '70%',
        maxWidth: '1056px',
        maxHeight: '90vh',
        height: 'auto',
        panelClass: 'custom-dialog-panel',
        topicAreaId: this.getSelectedTopicAreaId(),
      },
    });
    dialogRef.afterClosed().subscribe((course: CourseGalleryItemDTO | null) => {
      if (course) {
        this.dataService.setCourseId(index, course);
      }
    });
  }

  onAddPhaseButtonClickedEvent() {
    this.dataService.addPhase();
  }

  onRemovePhaseButtonClickedEvent(index: number) {
    this.dataService.removePhase(index);
  }

  onRemoveCourseButtonClickedEvent(index: number) {
    this.dataService.setCourseId(index, null);
  }

  getPhaseKey() {
    return this.dataService.formGroupSettings.controls.phaseFrequencyId.value;
  }

  getSelectedTopicAreaId(): string | null {
    return this.dataService.formGroupSettings.controls.topicAreaId.value;
  }

  getPhaseName(index: number) {
    const phaseKey = this.getPhaseKey();
    const phase = this.transloco.translate(
      `portal.learningPlans.builder.stepper.settings.form.phaseFrequency.options.${phaseKey}`,
    );
    return `${phase} ${index + 1}`;
  }
}
