<mat-chip
  *transloco="let t"
  [matTooltip]="
    t('portal.learningPlans.statuses.' + status.toLowerCase() + '.tooltip')
  "
  class="rounded-full"
  [ngClass]="getBgColorClass"
>
  {{ t('portal.learningPlans.statuses.' + status.toLowerCase() + '.name') }}
</mat-chip>
