@if (learningPlanInstanceDetails$ | async; as learningPlanInstanceDetails) {
  <div class="mx-8 mt-20" *transloco="let t" @easeInFast>
    <div class="mb-8 flex w-full items-center justify-between gap-2">
      <h1 class="text-3xl font-semibold">
        {{
          'portal.learningPlans.learningPlanNameKeys.' +
            learningPlanInstanceDetails.name
            | translocoWithFallback
              : undefined
              : learningPlanInstanceDetails.name
        }}
        @if (learningPlanInstanceDetails.status) {
          <app-learning-plan-status
            class="inline-block align-middle text-base"
            [status]="learningPlanInstanceDetails.status"
          ></app-learning-plan-status>
        }
      </h1>
      <div class="flex gap-2">
        <button
          class="flex items-center gap-0.5 rounded-lg bg-blue p-2.5 hover:bg-blue-hover"
          id="ProductFruitsEnrollButton"
          (click)="onEnrollButtonClick()"
        >
          <mat-icon class="hvs-icon-lg text-white">add</mat-icon>
          <mat-icon class="hvs-icon-lg text-white">person</mat-icon>
        </button>
        @if (canEdit(learningPlanInstanceDetails.status)) {
          <button
            class="shadow-xs rounded-lg px-4 py-2.5 text-sm font-semibold shadow-[inset_0_0_0_1px_#D1D5DB,0_1px_2px_0_rgba(0,0,0,0.05)] hover:bg-grey-veryLightGrey"
            (click)="onEditButtonClick()"
          >
            {{ 'portal.learningPlans.detailPage.editButton' | transloco }}
          </button>
        }
      </div>
    </div>
    <mat-tab-group
      class="remove-border-bottom"
      [(selectedIndex)]="selectedTabIndex"
    >
      <mat-tab [label]="t('portal.learningPlans.detailPage.tabs.overview')">
        <div class="mt-10">
          <app-learning-plan-statistics
            [startAt]="learningPlanInstanceDetails.startAt"
            [numberOfEnrolledStudents]="
              learningPlanInstanceDetails.numberOfEnrolledStudents
            "
            [numberOfCompletedStudents]="
              learningPlanInstanceDetails.numberOfCompletedStudents
            "
            [numberOfEnrolledStudentsLearningPlanStarted]="
              learningPlanInstanceDetails.numberOfEnrolledStudentsLearningPlanStarted
            "
          />
        </div>
        <div class="mt-10 w-full">
          <app-learning-plan-structure
            [phases]="learningPlanInstanceDetails.orderedPhases || []"
            [looping]="isLooping"
          ></app-learning-plan-structure>
        </div>
      </mat-tab>

      <mat-tab [label]="t('portal.learningPlans.detailPage.tabs.participants')">
        <div class="mt-5">
          <app-learning-plan-enrolled-student-table
            [tabIndex]="selectedTabIndex"
          />
        </div>
      </mat-tab>
    </mat-tab-group>
    <!--router outlet for route aware dialog-->
    <router-outlet></router-outlet>
  </div>
}
