@if (!hasSelectedCourse) {
  <div
    class="relative rounded-xl p-2"
    [ngClass]="{ 'bg-[#c91d1d1a]': errorMessage }"
  >
    <div
      tabindex="0"
      class="flex w-full cursor-pointer items-center justify-center gap-2 rounded-lg border border-dashed"
      [ngClass]="{
        'border-grey': !errorMessage,
        'border-cancel bg-grey-lightGrey': errorMessage,
        'p-4': true,
      }"
      (click)="handleCourseSelection()"
      (keydown.enter)="handleCourseSelection()"
      (keydown.space)="handleCourseSelection()"
    >
      <mat-icon class="material-icons-outlined hvs-icon-lg !text-blue">
        add
      </mat-icon>
      <span class="whitespace-pre text-sm font-semibold text-blue">
        {{ label }}
      </span>
    </div>
  </div>
} @else {
  <app-course-card
    *transloco="let t"
    [title]="
      t('portal.courses.courseTitleKeys.' + control.value?.name + '.name')
    "
    (removeCourseButtonClickedEvent)="handleRemoveCourse()"
  />
}
<div class="h-5 justify-items-center">
  @if (errorMessage) {
    <mat-error *transloco="let t" class="text-center text-sm">
      {{ t('general.inputValidation.' + errorMessage) }}
    </mat-error>
  }
</div>
