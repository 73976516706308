import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
  providedIn: 'root',
})
export class SepTableSelectionService<T> {
  private readonly selectionSubject: BehaviorSubject<SelectionModel<T>> = new BehaviorSubject(
    new SelectionModel<T>(true, []),
  );

  selection$: Observable<SelectionModel<T>> = this.selectionSubject.asObservable();

  selectRow(row: T) {
    const currentSelection = this.selectionSubject.getValue();
    currentSelection.toggle(row);
    this.selectionSubject.next(currentSelection);
  }

  toggleAllRows(dataView: T[]) {
    const currentSelection = this.selectionSubject.getValue();
    const allSelected = dataView.every((row) => currentSelection.isSelected(row));

    dataView.forEach((row) => {
      if (allSelected) {
        currentSelection.deselect(row);
      } else {
        // Only select rows that are currently not selected
        if (!currentSelection.isSelected(row)) {
          currentSelection.select(row);
        }
      }
    });

    this.selectionSubject.next(currentSelection);
  }

  clearSelection() {
    const currentSelection = this.selectionSubject.getValue();
    currentSelection.clear();
    this.selectionSubject.next(currentSelection);
  }

  updateSelection(filteredData: T[]): void {
    const currentSelection = this.selectionSubject.getValue();
    const preservedSelection = new Set(currentSelection.selected);
    const updatedSelection = filteredData.filter((row) => preservedSelection.has(row));

    currentSelection.clear();
    updatedSelection.forEach((row) => currentSelection.select(row));

    this.selectionSubject.next(currentSelection);
  }
}
