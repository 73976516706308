<header class="pl-6 pt-6">
  <div
    class="flex size-12 items-center justify-center rounded-3xl bg-blue-light p-3 text-blue"
  >
    <mat-icon
      class="material-icons-outlined items-center justify-center text-xl"
      style="display: inline-flex"
      >person_add</mat-icon
    >
  </div>
</header>
@switch (step) {
  @case (dialogSteps.ENROLL) {
    @if (isLearningPlansRoute) {
      <app-learning-plan-enrollment-container
        class="h-full min-h-max"
        (userCreationButtonClickEvent)="showUserCreationContent()"
        (addLicensesButtonClickEvent)="onAddLicensesButtonClick()"
        (studentSuccessfullyEnrolledEvent)="closeDialog()"
        (abortButtonClickEvent)="closeDialog()"
      ></app-learning-plan-enrollment-container>
    } @else {
      <app-course-enrollment-container
        class="h-full min-h-max"
        (userCreationButtonClickEvent)="showUserCreationContent()"
        (addLicensesButtonClickEvent)="onAddLicensesButtonClick()"
        (studentSuccessfullyEnrolledEvent)="closeDialog()"
        (abortButtonClickEvent)="closeDialog()"
      ></app-course-enrollment-container>
    }
  }
  @case (dialogSteps.CREATE) {
    <app-user-creation-container
      class="h-full"
      (userSuccessfullyCreatedEvent)="handleUserSuccessfullyCreatedEvent()"
      (backButtonClickEvent)="goBack()"
      (csvUploadButtonClickEvent)="showCsvUploadContent()"
    />
  }
  @case (dialogSteps.CSV) {
    <app-user-csv-upload-container
      class="h-full"
      (backButtonClickEvent)="showUserCreationContent()"
      (usersSuccessfullyCreatedEvent)="handleUserSuccessfullyCreatedEvent()"
    ></app-user-csv-upload-container>
  }
}
