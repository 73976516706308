import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  standalone: true,
  templateUrl: './slide-toggle.component.html',
})
export class SlideToggleComponent {
  @Input() public checked = false;
  @Input() public label = '';
  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onToggleChange(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
