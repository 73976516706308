import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-phase-card',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './phase-card.component.html',
})
export class PhaseCardComponent {
  @Input() name?: string;
  @Input() displayRemovePhaseButton = false;
  @Output() removePhaseButtonClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick() {
    this.removePhaseButtonClickedEvent.emit();
  }
}
