import { Component, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { merge, Subject, takeUntil } from 'rxjs';
import { TranslocoDirective } from '@jsverse/transloco';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-input-field',
  standalone: true,
  imports: [ReactiveFormsModule, MatIcon, MatTooltip, MatLabel, MatError, MatFormField, MatInput, TranslocoDirective],
  templateUrl: './input-field.component.html',
  styleUrl: './input-field.component.scss',
})
export class InputFieldComponent implements OnInit, OnDestroy {
  @Input({ required: true }) control: FormControl = new FormControl();
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() helpText = '';
  @Input() type = 'text';

  private readonly destroy$: Subject<void> = new Subject();

  errorMessage = signal('');

  ngOnInit() {
    merge(
      this.control.statusChanges.pipe(startWith(this.control.status)).pipe(takeUntil(this.destroy$)),
      this.control.valueChanges.pipe(startWith(this.control.value)).pipe(takeUntil(this.destroy$)),
    ).subscribe(this.updateErrorMessage.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateErrorMessage() {
    const { errors } = this.control;
    if (errors) {
      const [firstErrorKey] = Object.keys(errors);
      this.errorMessage.set(firstErrorKey || 'default');
    } else {
      this.errorMessage.set('');
    }
  }
}
