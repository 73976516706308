<div class="flex w-96 flex-col rounded-lg border" *transloco="let t">
  <div class="grow px-5 py-4">
    <p class="text-lg font-bold text-primary">
      {{
        'portal.courses.courseTitleKeys.' + courseInstance.course.name + '.name'
          | translocoWithFallback: undefined : courseInstance.course.name
      }}
    </p>
  </div>

  <div class="flex items-center px-5 pb-4">
    <div class="size-24">
      <app-doughnut-chart
        [percentage]="getCompletionPercentage()"
      ></app-doughnut-chart>
    </div>

    <div class="p-4">
      <p class="text-4xl font-semibold text-primary">
        {{ getCompletionPercentage() + '%' }}
      </p>
    </div>
    <div class="flex flex-col">
      <app-badge
        class="mb-1"
        [text]="courseInstance.numberOfEnrolledStudents.toString()"
        [type]="
          courseInstance.numberOfEnrolledStudents === 0
            ? 'warning'
            : 'transparent'
        "
        [icon]="'person'"
      >
      </app-badge>
      @if (learningPlanInstanceDetailsStatus) {
        <app-learning-plan-status [status]="learningPlanInstanceDetailsStatus">
        </app-learning-plan-status>
      }
    </div>
  </div>

  <div
    class="flex items-center justify-between gap-2 rounded-b-lg border-t bg-background-grey px-5 py-4"
  >
    <div class="shrink-0 grow-0">
      @if (courseInstance.phase) {
        <app-text-button
          class="flex-1"
          [attr.id]="isFirstCard ? 'ProductFruitsCardButton' : null"
          (click)="
            goToLearningPlanDetails(courseInstance.phase.learningPlanInstanceId)
          "
        >
          <div class="flex items-center">
            <mat-icon
              class="items-center justify-center text-sm"
              style="display: inline-flex"
              >build</mat-icon
            >
            {{ t('portal.courses.overview.manageLearningPlan') }}
          </div>
        </app-text-button>
      } @else {
        <app-primary-button
          [text]="t('portal.courses.overview.setupCourseButton')"
          (buttonClicked)="goToCourseDetails(courseInstance.id)"
        />
      }
    </div>
  </div>
</div>
