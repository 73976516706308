import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslocoDirective } from '@jsverse/transloco';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { merge, Subject, takeUntil } from 'rxjs';
import { MatError } from '@angular/material/form-field';
import { CourseCardComponent } from '../course-card/course-card.component';
import { startWith } from 'rxjs/operators';
import { CourseGalleryItemDTO } from '../../../domains/academy/learning-plan/models/LearningPlanDTO';

@Component({
  selector: 'app-course-select',
  standalone: true,
  imports: [MatIcon, TranslocoDirective, NgClass, MatError, CourseCardComponent, ReactiveFormsModule],
  templateUrl: './course-select.component.html',
})
export class CourseSelectComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() control: FormControl<CourseGalleryItemDTO | null>;
  @Output() selectCourse: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeCourse: EventEmitter<void> = new EventEmitter<void>();

  private readonly destroy$: Subject<void> = new Subject();

  errorMessage = '';
  hasSelectedCourse = false;

  updateErrorMessage() {
    const { errors } = this.control;
    if (errors) {
      const [firstErrorKey] = Object.keys(errors);
      this.errorMessage = firstErrorKey || 'default';
    } else {
      this.errorMessage = '';
    }
  }

  ngOnInit() {
    merge(
      this.control.statusChanges.pipe(startWith(this.control.status)),
      this.control.valueChanges.pipe(startWith(this.control.value)),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.hasSelectedCourse = !!this.control.value;
        this.updateErrorMessage();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleCourseSelection() {
    this.selectCourse.emit();
  }

  handleRemoveCourse() {
    this.removeCourse.emit();
  }
}
