import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { SlideToggleComponent } from '../../../../../shared/components/slide-toggle/slide-toggle.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loop-toggle',
  standalone: true,
  imports: [TranslocoDirective, SlideToggleComponent, CommonModule],
  templateUrl: './loop-toggle.component.html',
})
export class LoopToggleComponent {
  @Input() public checked = false;
  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onToggleChange(): void {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
