<div class="m-px flex w-min gap-6 rounded-lg bg-white px-5 py-6">
  <div class="flex items-center gap-2">
    <mat-icon class="material-icons-outlined hvs-icon-base mr-2 text-orange">
      description
    </mat-icon>
    <span class="whitespace-pre text-sm font-semibold">
      {{ title }}
    </span>
  </div>
  <mat-icon
    class="material-icons-outlined hvs-icon-lg cursor-pointer"
    (click)="onButtonClick()"
  >
    close
  </mat-icon>
</div>
