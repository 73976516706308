import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BadgeComponent } from '../../../../../shared/components/badge/badge.component';
import { PrimaryButtonComponent } from '../../../../../shared/components/primary-button/primary-button.component';
import { CourseInstanceDTO } from '../../models/CourseDTO';
import { TranslocoModule } from '@jsverse/transloco';
import { DoughnutChartComponent } from '../../../../../shared/components/doughnut-chart/doughnut-chart.component';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AnalyticsService } from '../../../../../core/services/analytics/analytics.service';
import { TextButtonComponent } from '../../../../../shared/components/text-button/text-button.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { LearningPlanInstanceDetailUiModel } from '../../../learning-plan/models/LearningPlanInstanceDetailUIModel';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { LearningPlanDataService } from '../../../learning-plan/services/learning-plan-data.service';
import { LearningPlanService } from '../../../learning-plan/services/learning-plan.service';
import { toLearningPlanInstanceDetailUiModel } from '../../../learning-plan/mappers/dto-mappers';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';
import { LearningPlanStatusComponent } from '../../../learning-plan/components/learning-plan-status/learning-plan-status.component';
import { LearningPlanStatus } from '../../../learning-plan/models/LearningPlanDTO';
import { TranslocoWithFallbackPipe } from '../../../../../core/pipe/transloco-with-fallback.pipe';

@Component({
  selector: 'app-course-instance-info-card',
  standalone: true,
  imports: [
    BadgeComponent,
    PrimaryButtonComponent,
    TranslocoModule,
    MatIconModule,
    DoughnutChartComponent,
    TextButtonComponent,
    LearningPlanStatusComponent,
    TranslocoWithFallbackPipe,
  ],
  templateUrl: './course-instance-info-card.component.html',
  styleUrl: './course-instance-info-card.component.css',
})
export class CourseInstanceInfoCardComponent implements OnDestroy, OnInit {
  @Input({ required: true }) courseInstance: CourseInstanceDTO;
  @Input() isFirstCard = false;

  private readonly destroy$: Subject<void> = new Subject();

  learningPlanInstanceDetailsStatus: LearningPlanStatus;
  learningPlanInstanceDetails$: BehaviorSubject<LearningPlanInstanceDetailUiModel | null> =
    new BehaviorSubject<LearningPlanInstanceDetailUiModel | null>(null);

  constructor(
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly learningPlanDataService: LearningPlanDataService,
    private readonly learningPlanService: LearningPlanService,
    private readonly snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.learningPlanInstanceDetails$.next(null);
    this.learningPlanDataService.setLearningPlanInstanceId(this.courseInstance.phase.learningPlanInstanceId);
    this.getLearningPlanDetails(this.courseInstance.phase.learningPlanInstanceId);
  }

  getLearningPlanDetails(id: string) {
    this.learningPlanService.getLearningPlanInstanceDetails(id).subscribe({
      next: (res) => {
        this.learningPlanDataService.setLearningPlaneName(res.learningPlanInstance.name);
        this.learningPlanInstanceDetails$.next(toLearningPlanInstanceDetailUiModel(res.learningPlanInstance));
        this.learningPlanInstanceDetailsStatus = res.learningPlanInstance.status;
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
      },
    });
  }

  goToCourseDetails(id: string): void {
    this.analyticsService.track('course_setup_clicked');
    void this.router.navigate(['/portal/courses', id]);
  }

  goToLearningPlanDetails(id: string): void {
    this.analyticsService.track('learning_plan_setup_clicked');
    void this.router.navigate(['/portal/learningPlans', id]);
  }

  getCompletionPercentage(): number {
    const { numberOfCourseCompletedStudents, numberOfEnrolledStudents } = this.courseInstance;
    return numberOfEnrolledStudents > 0
      ? Math.round((numberOfCourseCompletedStudents / numberOfEnrolledStudents) * 100)
      : 0;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
